<style lang="scss" src="../../styles/savings.scss" scoped></style>

<template>
  <div class="foreign-exchange-page">
    <!-- HEADER -->
    <HeaderSavingsDetails title="TransferWise" description="Foreign exchange (FX)" logoAssetFolderFile="logo-transferwise-large.svg" />

    <!-- MAIN -->
    <main class="m-b-20" :class="{ offer: isLoadingRequestCallback }">
      <!-- SUBHEADER INFO -->
      <spinner v-if="isLoadingRequestCallback" />

      <div v-else class="ui container">
        <SubHeaderSavingsDetails />
        <!-- OFFER INFO -->
        <OfferSavingsCallbackRequestedDetails v-if="isRequestedCallback" />

        <OfferSavingsDetails
          :islinkActive="true"
          linkVisit="https://prf.hn/click/camref:1101l5dER/destination:https%3A%2F%2Ftransferwise.com%2Fgb%2Fbusiness%2F"
          labelButtonVisit="Visit TransferWise"
          labelButtonCallback="Request Callback"
          logoAssetFolderFile="logo-transferwise-large.svg"
          imgWidth="286"
          imgHeight="48"
          @request-callback="requestCallbackClickHandler"
          v-else
        />
      </div>
    </main>

    <!-- FOOTER INFO -->
    <div class="card">
      <div class="ui container row">
        <div class="col-xs">
          <h3 class="m-t-25 title-footer">About TransferWise</h3>

          <div class="container-footer">
            <p>
              Sending money abroad can be costly, with fees hidden in exchange rates and charges not being shown upfront. That's why we've partnered with TransferWise, to bring you
              a service that will help you save on your FX payments. With no setup fee, creating an account takes a matter of minutes. Sending money abroad with TransferWise is up
              to 19x cheaper than with PayPal, giving you the opportunity to make significant savings. Learn more about TransferWise and set up your TransferWise for Business
              Account by following this <a href="https://prf.hn/click/camref:1101l5dER/destination:https%3A%2F%2Ftransferwise.com%2Fgb%2Fbusiness%2F">link</a>.
              <br />
              <br />5 reasons to use TransferWise:
            </p>

            <ul class="list-good-reasons">
              <li>Transparency – money is always converted at a mid-market exchange rate (the one you see on Google) and all fees are shown upfront</li>
              <li>Scalability – process 100s of payments at once with batch payments or automate them completely through API</li>
              <li>Speed - 23% of our transfers are instant (taking less than 20 seconds) and over 70% of transfers arrive within 24 hours</li>
              <li>Coverage - hold, convert and spend money in over 50 currencies with our Business multi-currency account</li>
              <li>
                Convenience - if you do business abroad in USA, Australia, New Zealand, or Europe, you can get local bank details to receive money in those countries without any
                fees
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { companyGetters } from '@/store/modules/company/routines'
import { postCompanyRequestSavingsCallback } from '@/api/company'
import HeaderSavingsDetails from '../../components/HeaderSavingsDetails'
import SubHeaderSavingsDetails from '../../components/SubHeaderSavingsDetails'
import OfferSavingsDetails from '../../components/OfferSavingsDetails'
import OfferSavingsCallbackRequestedDetails from '../../components/OfferSavingsCallbackRequestedDetails'

export default {
  name: 'ForeignExchange',
  components: {
    HeaderSavingsDetails,
    SubHeaderSavingsDetails,
    OfferSavingsDetails,
    OfferSavingsCallbackRequestedDetails
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID
    })
  },
  data() {
    return {
      categoryName: 'FX',
      isRequestedCallback: false,
      isLoadingRequestCallback: false
    }
  },
  methods: {
    requestCallbackClickHandler() {
      this.isLoadingRequestCallback = true
      const { companyId, categoryName } = this
      return postCompanyRequestSavingsCallback({ companyId, categoryName })
        .then(res => {
          this.isRequestedCallback = true
          this.isLoadingRequestCallback = false
        })
        .catch(e => {
          this.isRequestedCallback = false
          this.isLoadingRequestCallback = true
          console.log('e', e)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.foreign-exchange-page {
  width: 100%;
}
.offer {
  min-height: 349px;
  display: flex;
  align-items: center;
  justify-content: center;
}
li {
  word-spacing: 1px;
}
</style>
