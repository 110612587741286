<template>
  <div class="offer-container">
    <div class="row">
      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
        <div class="logo-container">
          <img :width="imgWidth" :height="imgHeight" :src="logoFile" />
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
        <div class="buttons-container">
          <a v-if="islinkActive" :href="linkVisit" :class="{ 'btn-utilities': type }" @click="(addTrackEvent('visit Transferwise'))" class="btn btn-sm btn-primary m-b-10" target="_blank" rel="noopener">{{
            labelButtonVisit
          }}</a>
          <button class="btn btn-sm btn-secondary" type="button" v-promise-btn @click="requestCallbackClickHandler">{{ labelButtonCallback }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OfferSavingsDetails',
  props: {
    logoAssetFolderFile: {
      required: true,
      type: String
    },
    linkVisit: {
      required: true,
      type: String
    },
    islinkActive: {
      required: true,
      type: Boolean
    },
    labelButtonVisit: {
      required: true,
      type: String
    },
    labelButtonCallback: {
      required: true,
      type: String
    },
    providerName: {
      type: String,
      defualt: null
    },
    category: {
      type: String,
      default: null
    },
    type: {
      required: false,
      type: String
    },
    imgWidth: {
      required: true,
      type: String
    },
    imgHeight: {
      required: true,
      type: String
    }
  },
  computed: {
    logoFile() {
      return `/img/savings/${this.logoAssetFolderFile}`
    }
  },
  methods: {
    requestCallbackClickHandler() {
      this.addTrackEvent('request callback')
      this.$emit('request-callback')
    },
    addTrackEvent(eventType) {
      if (this.providerName !== null && this.category !== null) {
        this.$ma.trackEvent({
          eventType: eventType,
          eventProperties: {
            category: this.category,
            provider: this.providerName
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/swoop/variables';

.offer-container {
  border-radius: 12px;
  background-color: $color-white;
  box-shadow: 0 2px 12px 0 rgba(51, 51, 79, 0.06);
  margin-bottom: 3rem;
  margin-top: 1rem;

  .logo-container {
    min-height: 204px;
    border: 1px solid var(--color-primary-200);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 32px 0 32px 66px;

    @media only screen and (max-width: $breakpoint-md-max) {
      margin: 1rem;
    }
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    @media only screen and (max-width: $breakpoint-md-max) {
      padding: 1rem;
    }

    .btn {
      width: 210px;
    }
  }
}
</style>
