<template>
  <div class="offer-container">
    <div class="row">
      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 bdr-divider">
        <div class="message-container">
          <h2>You have requested a callback</h2>
          <p>
            An email has been sent to confirm that you have requested a callback for more information.<br /><br />
            Our team is a busy bunch so please give us 2 working days to review and feel free to contact our friendly team anytime.
          </p>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
        <div class="buttons-container">
          <img src="@/assets/images/request-callback-illustration.svg" width="154" height="132" alt="logo" />
          <router-link :to="{ name: 'savings' }" class="btn btn-sm btn-primary m-b-10">
            View services
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OfferSavingsRequestedDetails'
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/swoop/variables';

.offer-container {
  border-radius: 12px;
  background-color: $color-white;
  box-shadow: 0 2px 12px 0 rgba(51, 51, 79, 0.06);

  .buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .btn,
    button {
      width: 164px;
    }
  }

  .message-container {
    max-width: 420px;
    padding: 2rem;
    @media only screen and (max-width: $breakpoint-sm) {
      max-width: 100%;
    }
  }

  .bdr-divider {
    border-right: 1px solid var(--color-primary-100);
  }
  .col-xs-12 {
    &:not(:last-child) {
      border-bottom: 2px solid $default-border-color;
    }
    .box {
      margin-bottom: 2rem;
    }
  }
}
</style>
